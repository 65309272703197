const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://rjshkhr.github.io/cleanfolio',
  title: 'JK.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'James Kaddissi',
  role: 'Education',
  description:
  "I am a full time student at Stevens Institute of Technology. I am pursuing a Bachelors in Computer Engineering with a concentration in Software Engineering, with an expected graduation of May 2026. Additionally I am pursuing a Masters in Computer Science in Steven's Accelerated Masters Program, with an expected graduation of January 2027",
  resume: 'https://example.com',
  social: {
    linkedin: 'https://www.linkedin.com/in/james-kaddissi-472339293/',
    github: 'https://github.com/james-kaddissi',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Poker Pay',
    description:
      'A mobile app for tracking and settling home poker games. The app solves the issue of too many small transactions per person, and offers additional poker related features. 5 star rating on the App Store.',
    stack: ['JavaScript', 'React Native'],
    sourceCode: 'https://github.com/james-kaddissi/PokerPay',
    livePreview: 'https://apps.apple.com/us/app/poker-pay/id6736601089',
  },
  {
    name: 'Universal Project Manager',
    description:
      'A CLI tool that offers a universay way to manage projects. Regardless of the language or framework, UPM contains a wide selection of consistent commands for all aspects of project management. Installed over 1250 times.',
    stack: ['Rust'],
    sourceCode: 'https://github.com/james-kaddissi/universal_project_manager',
    livePreview: 'https://crates.io/crates/upman',
  },
  {
    name: 'GS Edit',
    description:
      "An open source IDE that supports any language. It has everything a normal code editor has, with a few additional features that other editors don't including a more advanced Version Control system, an internal styles editor for allowing the user to customize every aspect of the editor from within, and support for extensions in Python or Rust.",
    stack: ['Python', 'Rust', 'Qt'],
    sourceCode: 'https://github.com/james-kaddissi/gs_edit',
    livePreview: 'https://pypi.org/project/GS-Edit/',
  },
  {
    name: 'Audio Video Generator',
    description:
      "An API that converts audio files to a video file of the audios waveform, with automatically generated subtitles. It supports production deployments and is being used for a new app I'm developing. The API is privately hosted on AWS, however the container can be downloaded and ran locally or deployed for production by anyone.",
    stack: ['Python', 'Flask', 'Docker'],
    sourceCode: 'https://github.com/james-kaddissi/audio_video_generator',
    livePreview: 'https://hub.docker.com/r/yezzerr/avg-container',
  }
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML/CSS',
  'JavaScript',
  'React',
  'React Native',
  'Python',
  'Rust',
  'C',
  'Git',
  'Node.js',
  'Express.js',
  'C#',
  'Flask',
  'SQL'
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'jameskaddissi@gmail.com',
  phone: '856-577-0951',
}

export { header, about, projects, skills, contact }

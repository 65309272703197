import { contact } from '../../portfolio'
import './Contact.css'

const Contact = () => {
  if (!contact.email) return null

  return (
    <section className='section contact center' id='contact'>
      <h2 className='section__title'>Contact</h2>
      <a href={`mailto:${contact.email}`}>
        <span type='button' className='btn btn--outline'>
          jameskaddissi@gmail.com
        </span>
      </a>
      <a href={`tel:${contact.phone}`}>
        <span type='button' className='btn btn--outline'>
          (856) 577-0951
        </span>
      </a>
    </section>

  )
}

export default Contact
